.WelcomeAnimationContainer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 20%;
  width: 100%;
  top: 35%;
  left: 0;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  margin: 0;
  padding: 0;
}

.textContainer {
  position: relative;
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  transition: transform 0.3s ease;
  width: 45vw;
}

.headline {
  color: #2c3e50;
  font-size: 2.75vw;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: 4.5vw;
  width: 45vw;
}

.message {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 16px;
  color: #fff;
  display: block;
  font-weight: 700;
  overflow: hidden;
  position: absolute;
  animation: open 3s ease;
  box-sizing: border-box;
  animation-iteration-count: 1;
  height: 4.5vw;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.word1 {
  font-family: tahoma;
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 4.5vw;
}

.message .word1 {
  animation: word-animation 3s ease-in-out;
  height: 4.5vw;
}
@keyframes word-animation {
  0%,
  5% {
    opacity: 0.5;
  }
  10%,
  22% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
  }
}
@keyframes open {
  0% {
    width: 0;
    border: 1px solid rgba(255, 255, 255, 0);
  }
  5% {
    border: 1px solid rgba(255, 255, 255, 0.25);
    width: 0;
  }
  10% {
    border: 1px solid rgba(255, 255, 255, 0.35);
  }
  20% {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  50% {
    width: 100%;
    opacity: 0.75;
  }

  75% {
    opacity: 0.5;
  }
  99% {
    opacity: 0.1;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  100% {
    width: 0;
    opacity: 0;
    border: none;
  }
}
