.Title {
  text-align: center;
  font-size: 1.5vw;
  font-weight: bold;
}

.Inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
}

.Button {
  margin: 0vw 1vw;
  padding: 0.5vw 2vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: #222;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}

.TextInputCustom {
  border-color: #fff !important;
  color: #fff !important;
  width: 100%;
}

.ErrorMessage {
  color: #dd2222;
  font-size: 1vw;
  height: 1vw;
}
