.MultiplayerGameContainer {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  width: 95%;
  font-size: 1.5vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3.2px);
  -webkit-backdrop-filter: blur(3.2px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 1vw 0vw;

  &:hover {
    .TitleWrapper {
      font-size: 2.5vw;
    }

    .NewGameButton {
      opacity: 1;
    }

    .YourTurn {
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }
    .Round {
      transform: none;
    }
  }
}
.MultiplayerGameWrapper {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.NewGameButton {
  position: absolute;
  right: -15%;
  top: -10%;
  margin: 0vw 1vw;
  padding: 0.75vw 1vw;
  background: #ffdc7f;
  border: 3px solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 28px;
  font-family: Jaro;
  transform: rotate(7deg);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.45);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  cursor: pointer;
  &:hover {
    border-color: rgba(255, 255, 255, 1);
    border: 3px solid;
  }
}
.TitleWrapper {
  position: relative;
  text-align: center;
  font-size: 1.5vw;
  color: #ffdc7f;
  width: 100%;
  margin-bottom: 1vw;
  transition: all 0.4s ease-in-out;
}
.MultiplayerGameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  margin-top: 0.25vw;
  font-size: 1.25vw;
  width: 80%;
  .Round {
    transform: translateX(5vw);
    transition: all 0.4s ease-in-out;
  }
}
.YourTurn {
  opacity: 0;
  cursor: pointer;
  padding: 0.5rem;
  background: #ffdc7f;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
  color: #fff;
  font-weight: bold;
  font-family: Jaro;
  transform: rotate(3deg);
  font-size: 1.2rem;
  transition: all 0.4s ease-in-out;
  &:hover {
    border-color: rgba(255, 255, 255, 1);
    border: 1px solid;
  }
}
.IconSpinner {
  -webkit-animation-name: spinner;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotateX(0deg);
  }
  to {
    -webkit-transform: rotateX(-360deg);
  }
}
/* all other browsers */
@keyframes spinner {
  from {
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  to {
    -moz-transform: rotateX(-360deg);
    -ms-transform: rotateX(-360deg);
    transform: rotateX(-360deg);
  }
}
