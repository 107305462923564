.GameResult {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  .Modal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    color: #ddd;
    width: 40%;
    height: 55vh;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(3.2px);
    -webkit-backdrop-filter: blur(3.2px);
    border: 1px solid rgba(255, 255, 255, 0.25);
  }

  .ModalWrapper {
    padding: 2.25vw;
    position: relative;

    .CreatePlayerButton {
      margin: 0vw 1vw;
      padding: 0.5vw 1vw;
      background: #ffdc7f;
      border: 3px solid;
      border-color: rgba(0, 0, 0, 0);
      border-radius: 15px;
      color: #fff;
      font-weight: bold;
      font-size: 2rem;
      font-family: Jaro;
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(5deg) translateX(5vw) translateY(-2vw);
      cursor: pointer;
      &:hover {
        border-color: rgba(255, 255, 255, 1);
        border: 3px solid;
      }
    }
  }
  .Button {
    width: 100%;
    text-align: center;
  }
}
.InputsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
.Welcome {
  margin-bottom: 2vw;
  font-size: 3vw;
  text-align: center;
}

.ChooseComplexity {
  font-size: 2vw;
  text-align: center;
  margin: 2vw 0vw 2vw 0vw;
}
.GameOver {
  text-align: center;
  font-size: 2.5vw;
  font-weight: bold;
  margin-bottom: 1vw;
}
.Complexity {
  width: 100%;
  display: flex;
  justify-content: center;
}

.PlayerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.NewPlayerInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 3vw;
  width: 80%;
  padding: 1vw 3vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  margin: 2vw 0;
}
.PlayerNameInput {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1vw 3vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  margin: 2vw 0;
}
.InputTitle {
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

.ButtonRow {
  position: absolute;
  top: 2vw;
  right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin: 0vw 1vw;
    padding: 0.5vw 2vw;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #222;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }
}

.NameError {
  margin-top: 0.5vw;
  color: #dd2222;
  font-size: 1vw;
}
