.NewGameButton {
  margin: 0vw 1vw;
  padding: 2vw 5vw;
  background: #ffdc7f;
  border: 3px solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  font-family: Jaro;
  transform: rotate(-7deg) translateY(20%);
  cursor: pointer;
  &:hover {
    border-color: rgba(255, 255, 255, 1);
    border: 3px solid;
  }
}

.TopBar {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 2vw;
  .BoardPart {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 80%;
  }
  .Title {
    font-family: "Kode Mono", monospace;
    letter-spacing: 12px;
    -webkit-text-stroke: 9px rgba(255, 255, 255, 0.22);
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 9vw;
    font-weight: 700;
    line-height: 232.96px;
    letter-spacing: -0.08em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    position: relative;
  }
}
.CurrentLevel {
  text-align: center;
  width: 50%;
}
.CurrentScore {
  text-align: center;
  width: 50%;
}
.Content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.BoardWrapper {
  width: 80%;
}
.SideBar {
  transform: translateY(-10vh);
  width: 20%;
  color: #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 80vh;
}

.Countdown {
  color: red;
  font-size: 48px;
}

.NewGameButtonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
}

.CreateWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  .CreateModal {
    display: flex;
    flex-direction: column;
    justify-content: start;
    color: black;
    background-color: #ccc;
    width: 60vw;
  }
  .Button {
    width: 100%;
    text-align: center;
  }
}

.StartPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

.Box {
  height: 50vh;
  width: 50vw;
  text-align: center;
}
