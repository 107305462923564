.Title {
  font-size: 2vw;
}
.Wrapper {
  display: flex;
  height: 100%;
  padding: 0.75vw;
}

.Box {
  position: relative;
  z-index: -10;
  width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  border-radius: 16px;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.1);
}
