.GameWrapper {
  color: #ddd;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
}

.TopBar {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 2vw;
  .BoardPart {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 80%;
  }
  .Title {
    font-family: 'Kode Mono', monospace;
    letter-spacing: 12px;
    -webkit-text-stroke: 9px rgba(255, 255, 255, 0.22);
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 9vw;
    font-weight: 700;
    line-height: 25vh;
    letter-spacing: -0.08em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    position: relative;
  }
  .HighscorePart {
    display: flex;
    justify-content: start;
    align-items: center;
    vertical-align: middle;
    width: 20%;
    font-weight: bold;
    max-height: 80%;
    overflow: scroll;
  }
}

.CurrentLevel {
  text-align: center;
  width: 50%;
}
.CurrentScore {
  text-align: center;
  width: 50%;
}
.Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.BoardWrapper {
  width: 50%;
}

.PlayerStatisticsWrapper {
  height: fit-content;
  transform: translateY(5vh) translateX(-2.5vw);
}
.SideBar {
  transform: translateY(-10vh);
  width: 15%;
  color: #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 80vh;
  transition: all 0.4s ease-in-out;

  &:hover {
    width: 20%;
    z-index: 5;
  }
}

.MultiplayerGames {
  position: relative;
  z-index: 1;
  transform: translateY(-10vh);
  width: 15%;
  color: #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 80vh;
  transition: all 0.4s ease-in-out;
  &:hover {
    width: 20%;
    z-index: 5;
  }
}

// .CreateWrapper {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   vertical-align: middle;
//   background-color: rgba(0, 0, 0, 0.25);
//   .CreateModal {
//     display: flex;
//     flex-direction: column;
//     justify-content: start;
//     color: black;
//     background-color: #ccc;
//     width: 60vw;
//   }
//   .Button {
//     width: 100%;
//     text-align: center;
//   }
// }
