.GameWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  color: #fff;
}

.Highscore {
  width: 20%;
}
