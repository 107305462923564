.Background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  background: linear-gradient(
    114.25deg,
    rgba(47, 16, 76, 0.4) 13.43%,
    #16325b 37.7%,
    #579eb0 59.95%,
    #78b7d0 72.83%,
    #ffdc7f 94.33%
  );

  z-index: -1;
}
.RectangleWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
}
.Rectangle {
  position: absolute;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(1px);
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  width: var(--width);
  height: var(--height);
  top: var(--yPosition);
  left: var(--xPosition);
  transform: rotate(var(--rotate));
}
