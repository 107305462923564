.NewGameButton {
  margin: 0vw 1vw;
  padding: 2vw 4vw;
  background: #ffdc7f;
  border: 3px solid;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  font-family: Jaro;
  transform: rotate(-7deg) translateY(20%);
  cursor: pointer;
  &:hover {
    border-color: rgba(255, 255, 255, 1);
    border: 3px solid;
  }
}
