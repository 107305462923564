.HighscoreWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  width: 95%;
  font-size: 1.5vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3.2px);
  -webkit-backdrop-filter: blur(3.2px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 1vw 0vw;

  &:hover {
    .TitleWrapper {
      font-size: 2.5vw;
    }
    .PlayerRow {
      font-size: 1.5vw;
      padding: 0.7vw;
      margin-top: 0.7vw;
    }
  }
}
.TitleWrapper {
  position: relative;
  text-align: center;
  font-size: 1.5vw;
  color: #ffdc7f;
  width: 100%;
  margin-bottom: 1vw;
  transition: all 0.4s ease-in-out;
}

.Cup {
  position: absolute;
  top: -5vh;
}

.PlayerRow {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(3.2px);
  -webkit-backdrop-filter: blur(3.2px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 0.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  margin-top: 0.25vw;
  font-size: 1vw;
  width: 80%;
  transition: all 0.4s ease-in-out;
}

.Rank {
  width: 10%;
}
.Name {
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 5%;
}
.Score {
  width: 15%;
  align-self: flex-end;
}
