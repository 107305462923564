.StatisticColumn {
  height: 100%;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  transform: rotate(5deg) translateY(-10%);
  z-index: 10;
  color: #fff;
}

.PlayerName {
  font-size: 2vw;
  margin-bottom: 2vw;
}
.Icon {
  text-align: center;
  img {
    width: 2.5vw;
  }
}
.ColumnRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  row-gap: 1vw;
}

.Column {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  vertical-align: middle;
  flex-direction: row;
  height: 9vh;
  width: 9vw;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.ColumnTitle {
  font-size: 2vw;
}
.ColumnValue {
  font-size: 2.5vw;
}
