.GameWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin-top: 12vh;
}

.BoardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.FakeColumn {
  .Row {
    .Column {
      border: 1px solid rgba(255, 255, 255, 0);
      background: rgba(255, 255, 255, 0);
      backdrop-filter: none;
      box-shadow: 0 4px 30px rgba(255, 255, 255, 0);
      -webkit-backdrop-filter: none;
    }
  }
}

.PlayerName {
  font-size: 2vw;
  margin-bottom: 2vw;
}
.Icon {
  text-align: center;
  img {
    width: 2.5vw;
  }
}
.ColumnRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  row-gap: 1vw;
}

.Column {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  vertical-align: middle;
  flex-direction: row;
  height: 9vh;
  width: 9vw;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}
.ColumnTitle {
  font-size: 2vw;
}
.ColumnValue {
  font-size: 2.5vw;
}

.HintRow {
  font-family: "Unbounded", sans-serif;
  color: #ffdc7f;
  font-weight: 700;
  display: flex;
  padding: 0.5vw 1vw;
  width: 30vw;
  font-size: 2vw;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  justify-content: start;
  position: relative;
  transform: translateX(-7%) translateY(10%) rotate(-1deg);
  z-index: 10;
  .correctWords {
    padding: 0.5vw;
    position: absolute;
    top: -4vw;
    font-size: 3vw;
    right: -10%;
    border-radius: 10px;
    height: 5vw;
    width: 3vw;
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    transform: rotate(10deg);
  }
}

.Board {
  display: grid;
  justify-content: space-around;
  vertical-align: middle;
  align-items: center;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  width: 36vw;
  height: 45vh;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3.3px);
  -webkit-backdrop-filter: blur(3.3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  font-family: "Unbounded", sans-serif;
  font-style: normal;
}
.Content {
  width: 60%;
  text-align: center;
}

.StatisticColumn {
  height: 100%;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  transform: rotate(5deg) translateY(-10%);
  z-index: 10;
  color: #fff;
}
