.ResultWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

.Title {
  text-align: center;
  font-size: 3vw;
  color: #ffdc7f;
}

.Result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3.2px);
  -webkit-backdrop-filter: blur(3.2px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 2vw;
  color: #fff;
}

.ColumnRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding: 1vw;
}

.Column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  vertical-align: middle;
  height: 10vh;
  width: 10vw;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0vw 1vw;
}
.ColumnTitle {
  font-size: 1.5vw;
}
.ColumnValue {
  font-size: 1.5vw;
}
.ButtonWrapper {
  text-align: center;
}
.FinishButton {
  margin: 1vw 0vw;
  padding: 1vw 2vw;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 1.5vw;
  font-family: Jaro;
}
